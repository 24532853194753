import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { createMetaManager } from 'vue-meta'
import { createHead } from "unhead";
import Vidle from 'v-idle-3'

import "@/assets/scss/all.scss";

const app = createApp(App);
const head = createHead();

axios.get("@/../urlConfig.json")
.then((res)=>{
  axios.defaults.baseURL = res.data.url // 直接從public的json檔去抓取
})


// build 這兩個網址 給桓榕
// axios.defaults.baseURL = "http://74.235.96.90:131/YuninERP/api"; // 給PM測的版本
// axios.defaults.baseURL = "https://erp.yunin.info:131/YuninERP/api"; // 正式上線的版本

//-------
// axios.defaults.baseURL = "http://172.190.122.212:131/YuninERP/api"; // 給莉莉測的版本
// axios.defaults.baseURL = "https://localhost:7221/api";
app.use(router).use(createPinia()).use(createMetaManager(false, {
  meta: { tag: 'meta', nameless: true }
})).use(Vidle);

axios.interceptors.request.use(
  (config) => {
    const JwtToken = JSON.parse(localStorage.getItem("userInfo"))?.token;

    if (JwtToken) {
      config.headers.Authorization = `Bearer ${JwtToken}`;
    }

    // if (localStorage.getItem("JwtToken")) {
    //   config.headers.Authorization =
    //     "Bearer " + localStorage.getItem("JwtToken");
    // }

    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      // localStorage.removeItem("JwtToken");
      localStorage.removeItem("userInfo");
      router.push({ path: "/CyberCloud/Login" });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("userInfo");
      router.push({ path: `/${localStorage.getItem("loginParams")}/Login` });
      return Promise.reject(error);
    }
  }
);

app.mount("#app");
