<script>
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      meta: [
        {
          "http-equiv": "Pragma",
          content: "no-cache",
        },
        {
          "http-equiv": "Cache-Control",
          content: "no-cache",
        },
        {
          "http-equiv": "Expires",
          content: "0",
        },
      ],
    });

    return {};
  },
};
</script>

<template>
  <Metainfo />
  <!-- <Menu /> -->
  <!-- <main> -->
  <RouterView />
  <!-- <GoToTop /> -->
  <!-- </main> -->
</template>
